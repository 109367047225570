import React from 'react';
import styled from '@emotion/styled';
import {
  Layout,
  SEO,
  Card,
  Question,
  HalfCard,
  FlexWrapper,
  SubTitle,
  FullHeightWrapper
} from '../components/elements';
import {useStaticQuery, graphql, Link} from 'gatsby'


const WelcomeCard = styled(Card)`
  ${tw`bg-beige`}
  
`


export default function indexPage() {


  const {secteurs, acteurs} = useStaticQuery(graphql`
  query GetSecteurs {
      secteurs:allContentfulSecteurs (sort: {order: ASC, fields: order}){
          nodes {
            contentful_id
            nom
            slug
          }
      }
      acteurs:allContentfulActeur {
        nodes {
          secteur {
            contentful_id
          }
        }
      }
  }
  `)


  return (
    <FullHeightWrapper>
    <SEO
      title="Le répertoire des acteurs du développement durable"
      description="Simplifier l’accès à une consommation durable et rendre ce devoir d’action citoyenne accessible à tous et sans effort."
      keywords={[`commerce`, `équitable`, `suisse`, `bio`, `local`, `répertoire`]}
    />
        <Question>Le répertoire des acteurs du développement durable en Suisse Romande</Question>
        <WelcomeCard>
          <p>
          En plus de vous simplifier la vie, ce site vous permet de contribuer à projeter la Suisse Romande 
          vers un avenir plus respectueux pour nos générations futures, pour notre planète et pour VOUS.
          </p>
        </WelcomeCard>
        <SubTitle>
          Sélectionnez un secteur :
        </SubTitle>
        <FlexWrapper>
          {secteurs.nodes.map((secteur, i) => {
              const currentActors = acteurs.nodes.filter(acteur => acteur.secteur.contentful_id === secteur.contentful_id);
              
              if(currentActors.length > 0)
              return(
                <HalfCard key={i}>
                  <Link to={"/" +secteur.slug}>
                    {secteur.nom} ({currentActors.length})
                  </Link>
                </HalfCard>
              )
              else
              return(
                <HalfCard key={i} empty>
                  <a href="#">{secteur.nom} ({currentActors.length})</a>
                </HalfCard>
              )
          })}        
        </FlexWrapper>
    </FullHeightWrapper>
  );
}
